// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedWithdrawalRequest: null
}

const WithdrawalRequests = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_WITHDRAWAL_REQUESTS':
      return { ...state, allData: action.data }
      case 'GET_FILTERED_DATA':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
      case 'GET_WITHDRAWAL_REQUEST':
        return { ...state, selectedWithdrawalRequest: action.selectedWithdrawalRequest }
      default:
        return { ...state }
  }
}
export default WithdrawalRequests
