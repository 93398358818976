// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedSubscription: null,
  selectedSubscriptionRoiHistory: [],
}

const AllSubscriptions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_SUBSCRIPTIONS':
      return { ...state, allData: action.data }
    case 'GET_FILTERED_SUBSCRIPTIONS':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_SUBSCRIPTION':
      return { ...state, selectedSubscription: action.selectedSubscription }
    case 'GET_SUBSCRIPTION_ROI_HISTORY':
      return {
        ...state,
        selectedSubscriptionRoiHistory: action.data
      }
    case 'GET_FILTERED_SUBSCRIPTION_ROI_HISTORY':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    default:
      return { ...state }
  }
}
export default AllSubscriptions
