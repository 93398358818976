const initialState = {
    allData: [],
    data: [],
    total: 1,
    params: {}, 
    selectedInvestmentSubs: null
  }
  
  const investmentSubs = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ALL_INVESTMENT_SUBS':
        return { ...state, allData: action.data }
      case 'GET_FILTERED_INVESTMENT_SUBS':
        return {
          ...state,
          data: action.data,
          total: action.totalPages,
          params: action.params
        }
        case 'GET_INVESTMENT_SUB':
      return { ...state, selectedInvestmentSubs: action.selectedInvestmentSubs }
      default:
        return { ...state }
    }
  }
  export default investmentSubs
  